@import "~bootstrap/scss/bootstrap";

.add-button-wrapper {
  display: flex;
  justify-content: center;
  margin-top: -15.5px;
  margin-bottom: -15.5px;
  z-index: 10;

  > button {
    border: 1px solid #CCC;
    border-radius: 25px;
    width: 30px;
    height: 30px;
    vertical-align: middle;
    line-height: 1px;
  }
}

.rc-time-picker.form-control {
  .rc-time-picker-input {
    display: block;
    border: 0;
    padding: 0;
    height: auto;
    font-size: inherit;
  }
}

.rc-time-picker-panel-inner {
  font-size: inherit;
}

.rc-time-picker-panel-input-wrap {
  padding: 0.375rem 0.75rem;
}
